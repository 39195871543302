import React from "react";

import { CONTACT } from "../../config/urls";

import Phone from "../../images/phone-call.svg";

import {
  GetInTouchContainer,
  GetInTouchext,
  PhoneIcon,
  GetInTouchButton,
  GetInTouchDiv,
  SmallText,
  TextSection,
} from "./styles";

const GetInTouchSection = ({ mailto, description, title, icon }) => {
  return (
    <GetInTouchContainer>
      <div data-aos="fade" className="container">
        <GetInTouchDiv id="contact_us">
          <TextSection>
            <PhoneIcon src={icon ? icon : Phone} alt="phone icon" />
            <GetInTouchext>
              {title ? title : "To find out more about our services"}
              <SmallText>
                {description
                  ? description
                  : "Please don’t hesitate to contact us"}
              </SmallText>
            </GetInTouchext>
          </TextSection>
          <GetInTouchButton href={`mailto:${mailto ? mailto : CONTACT.MAIL}`}>
            Contact Us
          </GetInTouchButton>
          <GetInTouchButton mobile href={`tel:${CONTACT.PHONE}`}>
            Contact Us
          </GetInTouchButton>
        </GetInTouchDiv>
      </div>
    </GetInTouchContainer>
  );
};

export default GetInTouchSection;
